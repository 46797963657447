<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    header="Cliente Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
  <div class="col-12">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-9">
        <label for="nombre"
          ><strong>Nombre Completo: </strong> <span class="p-invalid">*</span></label
        >
        <InputText
          id="nombre"
          v-model.trim="data_cliente.nombre"
          required="true"
          autofocus
        />
        <small class="p-invalid" v-if="errors.nombre">{{
          errors.nombre[0]
        }}</small>
      </div>

      <div class="field col-12 md:col-3">
        <label for="telefono"><strong>Tel&eacute;fono: </strong></label>
        <InputText
          id="telefono"
          v-model="data_cliente.telefono"
          required="true"
        />
        <small class="p-invalid" v-if="errors.telefono">{{
          errors.telefono[0]
        }}</small>
      </div>
      <div class="field col-12 md:col-5">
        <label for="ci_nit"><strong>Tipo doc. Identidad: </strong></label>
        <Dropdown
          id="tipo_documento_identidad"
          v-model="tipo_documento_selected"
          :options="tipos_documentos"
          optionLabel="label"
          placeholder="Seleccione tipo documento..."
        >
        </Dropdown>
        <small class="p-invalid" v-if="errors.ci_nit">{{
          errors.ci_nit[0]
        }}</small>
      </div>
      <div class="field col-11 md:col-3">
        <label for="ci_nit"><strong>CI/NIT: </strong></label>
        <InputText id="ci_nit" v-model="data_cliente.ci_nit" />
        <small class="p-invalid" v-if="errors.ci_nit">{{
          errors.ci_nit[0]
        }}</small>
      </div>
      <div class="field col-1 md:col-1">
        <label for="ci_nit"><strong>Ext. </strong></label>
        <InputText id="ci_nit" v-model="data_cliente.complemento_documento" />
        <small class="p-invalid" v-if="errors.ci_nit">{{
          errors.ci_nit[0]
        }}</small>
      </div>


      <div class="field col-12 md:col-6">
        <label for="domicilio"><strong>Domicilio: </strong> </label>
        <Textarea
          id="domicilio"
          v-model="data_cliente.domicilio"
          required="true"
        />
        <small class="p-invalid" v-if="errors.domicilio">{{
          errors.domicilio[0]
        }}</small>
      </div>
      <div class="field col-12 md:col-3">
        <label for="correo"><strong>Correo Electr&oacute;nico: </strong> </label>
        <InputText id="correo" v-model="data_cliente.correo" required="true" />
        <small class="p-invalid" v-if="errors.correo">{{
          errors.correo[0]
        }}</small>
      </div>
      <div class="field col-12 md:col-3">
        <label for="estado"><strong>Estado: </strong><span class="p-invalid">*</span></label>
        <Dropdown
          id="estado"
          v-model="data_cliente.estado"
          :disabled="!data_cliente.id"
          :options="estados"
          optionLabel="label"
          placeholder="Seleccione un estado..."
        >
         <template #value="slotProps">
           <span :class="'product-badge ' + claseEstado[slotProps.value.value]">
            {{ slotProps.value.label }}
          </span>
        </template>
        </Dropdown>
      </div>
    </div>
  </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>Campos Requeridos: </strong> <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="guardarCliente"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import TipoDocumentoIdentidadService from "@/service/TipoDocumentoIdentidadService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  clienteService: null,
  tipoDocumentoIdentidadService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_cliente: this.cliente,
      tipo_documento_selected: {},
      enviado: false,
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      tipos_documentos: [],
    };
  },
  created() {
    this.clienteService = new ClienteService();
    this.tipoDocumentoIdentidadService = new TipoDocumentoIdentidadService();

    this.cargarTiposDocumentos();

  },
  methods: {
    cargarTiposDocumentos() {
      this.tipoDocumentoIdentidadService
        .getAllTipoDocumentoIdentidad()
        .then((data) => {
          data.forEach(element => {
            if (this.data_cliente.id && this.data_cliente.tipo_documento == element.id) {
              this.tipo_documento_selected = {
                label: element.codigo + ' - ' + element.descripcion,
                value: element.id,
              } 
            } else {
              if (element.id == 1) {
                this.tipo_documento_selected = {
                  label: element.codigo + ' - ' + element.descripcion,
                  value: element.id,
                } 
                
              }
            }
            this.tipos_documentos.push({
              label: element.codigo + ' - ' + element.descripcion,
              value: element.id,
            });
            
          });
        });
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
    guardarCliente() {
      this.errors = {};
      this.enviado = true;
      //editar cliente
      if (this.cliente.id) {
        let cliente_enviar = {
          ...this.data_cliente,
          estado: this.data_cliente.estado.value,
          tipo_documento: this.tipo_documento_selected.value
        };
        this.clienteService.updatedCliente(cliente_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_cliente;
        this.data_cliente = {
          ...this.data_cliente,
          estado: this.data_cliente.estado.value,
          tipo_documento: this.tipo_documento_selected.value

        };
        this.clienteService.sendClienteNuevo(this.data_cliente).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_cliente = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    cliente(val) {
      this.data_cliente = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>