<template>
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<span class="text-900 line-height-3">Clientes</span>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-6 lg:px-8">
		<Toast />
		<div
			class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
		>
			<div>
				<div class="font-medium text-3xl text-900">Administrar Clientes</div>
				<div class="flex align-items-center text-700 flex-wrap">
					<div class="mr-5 flex align-items-center mt-3">
						<i class="pi pi-briefcase mr-2"></i>
						<span>{{ totalRegistros }} Clientes</span>
					</div>
				</div>
			</div>
			<div class="flex mt-3 lg:mt-0">
				<Button
					label="Nuevo"
					v-if="'Cliente Crear' in auth.user.permissions"
					v-tooltip.top="'Nuevo Cliente'"
					icon="pi pi-plus"
					class="p-button-outlined mr-2"
					@click="openNuevo"
				/>
			</div>
		</div>
		<div class="mt-4">
			<DataTable
				ref="dtclientes"
				:value="clientes"
				:loading="cargando"
				dataKey="id"
				:paginator="true"
				:rows="10"
				:rowHover="true"
				:filters="buscar"
				class="p-datatable-sm"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[5, 10, 25]"
				currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Clientes"
				responsiveLayout="scroll"
			>
				<template #header>
					<div
						class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
					>
						<span class="block mt-2 md:mt-0 p-input-icon-left">
							<i class="pi pi-search" />
							<InputText
								v-model="buscar['global'].value"
								placeholder="Buscar cliente..."
							/>
						</span>
					</div>
				</template>

				<template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>
				<template #empty>
					<span class="flex align-items-center justify-content-center p-invalid"
						>No existen Registros!</span
					></template
				>
				<Column header="#">
					<template #body="slotProps">
						{{ 1 + slotProps.index }}
					</template>
				</Column>

				<Column
					field="id"
					header="CÓDIGO"
					:sortable="true"
					exportHeader="CÓDIGO"
				>
					{{ data.id }}
				</Column>
				<Column
					field="nombre"
					header="NOMBRE COMPLETO"
					exportHeader="NOMBRE COMPLETO"
				>
					{{ data.nombre }}
				</Column>
				<Column field="ci_nit" header="CI/NIT">
					{{ data.ci_nit }}
				</Column>
				<Column field="telefono" header="TELÉFONO">
					{{ data.telefono }}
				</Column>
				<Column field="domicilio" header="DOMICILIO">
					{{ data.domicilio }}
				</Column>
				<Column field="correo" header="CORREO ELECTRÓNICO">
					{{ data.correo }}
				</Column>
				<Column field="estado" header="ESTADO">
					<template #body="{ data }">
						<span :class="'product-badge ' + claseEstado[data.estado.value]">
							{{ data.estado.label }}
						</span>
					</template>
				</Column>
				<Column headerStyle="min-width:10rem;" header="ACCIONES">
					<template #body="slotProps">
						<Button
							class="p-button-icon-only p-button-raised"
							v-tooltip.top="'Ver Acciones'"
							icon="pi pi-ellipsis-v"
							@click="$refs['menu' + slotProps.data.id].toggle($event)"
						/>
						<Menu
							:ref="'menu' + slotProps.data.id"
							:popup="true"
							:model="acciones(slotProps.data)"
						>
						</Menu>
					</template>
				</Column>
			</DataTable>
		</div>

		<Dialog
			v-model:visible="deleteClienteDialog"
			:style="{ width: '450px' }"
			header="Confirmar"
			:modal="true"
		>
			<div class="flex align-items-center justify-content-center">
				<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
				<span v-if="cliente"
					>Estas Seguro que quieres eliminar al cliente
					<b>{{ cliente.nombre }}</b> ?</span
				>
			</div>
			<template #footer>
				<Button
					label="No"
					icon="pi pi-times"
					class="p-button-secondary"
					@click="deleteClienteDialog = false"
				/>
				<Button
					label="Si"
					icon="pi pi-check"
					class="p-button-primary"
					@click="deleteCliente"
				/>
			</template>
		</Dialog>
		<ClienteCreate
			:show="clienteDialog"
			:cliente="cliente"
			@closeModal="ocultarDialog"
			@actualizarListado="cargarClientes"
		>
		</ClienteCreate>
	</div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ClienteService from "@/service/ClienteService";
import { useAuth } from "@/stores";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";

export default {
	components: {
		ClienteCreate,
	},
	data() {
		return {
			clientes: null,
			cargando: true,
			clienteDialog: false,
			deleteClienteDialog: false,
			cliente: {},
			buscar: {},
			enviado: false,
			estados: [
				{ label: "Inactivo", value: 0 },
				{ label: "Activo", value: 1 },
			],
			errors: {},
			totalRegistros: 0,
			claseEstado: ["status-outofstock", "status-instock"],
		};
	},
	clienteService: null,
	auth: null,
	created() {
		this.auth = useAuth();
		this.clienteService = new ClienteService();
		this.initBuscar();
	},
	mounted() {
		this.cargarClientes();
	},
	methods: {
		acciones(datos) {
			let permisosUsuarioAuth = this.auth.user.permissions;
			return [
				{
					label: "Editar",
					disabled: "Cliente Editar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-pencil",
					command: () => {
						this.editCliente(datos);
					},
				},
				{
					label: "Ver Detalle",
					disabled: "Cliente Ver Detalle" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-eye",
					command: () => {
						this.verDetalle(datos);
					},
				},
				{
					label: "Eliminar",
					disabled: "Cliente Eliminar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-trash",
					command: () => {
						this.confirmDeleteCliente(datos);
					},
				},
			];
		},
		cargarClientes() {
			this.clienteService.getClientesAll().then((data) => {
				this.clientes = data;
				this.cargando = false;

				this.clientes.forEach((cliente) => {
					cliente.created_at = new Date(cliente.created_at);
					cliente.updated_at = new Date(cliente.updated_at);
					cliente.estado = {
						label: this.textoEstado(cliente.estado),
						value: cliente.estado,
					};
				});
			});
		},
		openNuevo() {
			this.cliente = {
				estado: { label: "Activo", value: 1 },
			};
			this.enviado = false;
			this.clienteDialog = true;
		},
		ocultarDialog() {
			this.clienteDialog = false;
			this.enviado = false;
		},
		ocultalModalCliente() {
			this.clienteDialog = false;
			this.cliente = {};
		},
		textoEstado(estado) {
			return estado == 1 ? "Activo" : "Inactivo";
		},
		editCliente(cliente) {
			this.cliente = {
				...cliente,
				estado: {
					label: this.textoEstado(cliente.estado.value),
					value: cliente.estado.value,
				},
			};

			this.clienteDialog = true;
		},
		confirmDeleteCliente(cliente) {
			this.cliente = { ...cliente };
			this.deleteClienteDialog = true;
		},
		exportCSV() {
			this.$refs.dtclientes.exportCSV();
		},
		deleteCliente() {
			this.clienteService.deleteCliente(this.cliente).then((data) => {
				if (data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: data.mensaje,
            life: 3000,
          });
          this.cargarClientes();
          this.deleteClienteDialog = false;
          this.cliente = {};
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.mensaje,
            life: 3000,
          });
          this.deleteClienteDialog = false;
        }
			});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.clientes.length; i++) {
				if (this.clientes[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		initBuscar() {
			this.buscar = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			};
		},
		verDetalle(cliente) {
			this.$router.push(`/cliente/${cliente.id}/detalle`);
		},
		formatDate(value) {
			return value.toLocaleDateString("es-ES", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
			});
		},
	},
	watch: {
		clientes() {
			this.totalRegistros = this.clientes.length;
		},
	},
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
</style>
